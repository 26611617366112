import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { datadogRum } from '@datadog/browser-rum-slim';
import App from 'next/app';
import { ThemeProvider } from 'styled-components';

import { AccountProvider } from '../components/context/account-context';
import { AlertProvider } from '../components/context/alert-context';
import GlobalStyles, { theme } from '../components/GlobalStyles';
import Layout from '../components/Layout';

import '../components/GlobalStyles/fonts.css';

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'ne-link-web',
  env: process.env.NEXT_PUBLIC_ENVIRONMENT,
  version: process.env.NEXT_PUBLIC_DATADOG_VERSION,
  sessionSampleRate: 5,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask',
  silentMultipleInit: true,
  beforeSend: (event) => {
    if (event.type === 'error' && event.error.message.includes('Script error.')) {
      return false;
    }
  },
});

export default class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;
    return (
      <ReactErrorBoundary onError={datadogRum.addError}>
        <ThemeProvider theme={theme}>
          <AlertProvider>
            <AccountProvider>
              <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
                <Layout noLayout={Component.noLayout}>
                  <GlobalStyles />
                  <Component {...pageProps} />
                </Layout>
              </GoogleReCaptchaProvider>
            </AccountProvider>
          </AlertProvider>
        </ThemeProvider>
      </ReactErrorBoundary>
    );
  }
}
