import SC from '../headerElements';

const SearchIcon = ({ toggleSearch, searchOpen, className, testid }) => {
  const searchActive = searchOpen && `active`;

  return (
    <SC.SearchWrapper className={className}>
      <SC.StyledSearchIcon
        className={`search-icon ${searchActive}`}
        onClick={() => toggleSearch()}
        data-testid={testid}
      >
        <span className="search-line main-line"></span>
        <span className="search-circle"></span>
        <span className="search-line second-line"></span>
      </SC.StyledSearchIcon>
    </SC.SearchWrapper>
  );
};
export default SearchIcon;
