/* eslint-disable react/jsx-no-target-blank */
import styled from 'styled-components';

import { Box, Col, Contain, Row } from '../Grid';

const Title = styled.h3`
  margin: 0;
  text-transform: uppercase;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;

const Links = styled.ul`
  list-style: none;
  padding: 1rem 0 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.75;

  a {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
  }
`;

const StyledRow = styled(Row)`
  padding-top: 1rem;
  .fp-logo {
    width: 5.875rem;
    padding-right: 0.5rem;
  }
`;
const StyledCol = styled(Col)`
  padding: 0.25rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const SocialMediaLinks = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-top: ${({ theme }) => theme.space.xs};
  a {
    width: 1.875rem;
    height: 1.875rem;
  }
  a.instagram-icon {
    margin: 0 0.75rem;
  }
`;
const FooterNav = () => {
  return (
    <Contain>
      <Row flexDirection={['column', 'row']} justifyContent="space-between">
        <Col mb="md" display="flex" flexDirection="column">
          <Title>Get to Know Us</Title>
          <Links>
            <li>
              <a href={`${process.env.NEXT_PUBLIC_WP_URL}/our-story/about-us`}>Our Story</a>
            </li>
            <li>
              <a
                href="https://image.marketing.link.sg/lib/fe3a15717564047b711372/m/1/8c5f381d-ba89-4f47-abad-ee12b198402b.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <a href={`${process.env.NEXT_PUBLIC_WP_URL}/legal/privacy-notice`}>Privacy Notice</a>
            </li>
            <li>
              <a
                href="https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme"
                target="_blank"
                rel="noreferrer"
              >
                Report Vulnerability
              </a>
            </li>
            <li>
              <a
                href="https://support.link.sg/hc/en-us#contact-us"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </li>
          </Links>
        </Col>
        <Col mb="md" display="flex" flexDirection="column">
          <Title>JOIN US</Title>
          <Links>
            <li>
              <a href={`${process.env.NEXT_PUBLIC_WP_URL}/be-our-partner/linkpoints`}>
                Be a Partner
              </a>
            </li>
          </Links>
        </Col>
        <Col mb="md" display="flex" flexDirection="column">
          <Title>FOLLOW US</Title>
          <SocialMediaLinks>
            <a
              target="_blank"
              href="https://www.facebook.com/LinkRewardsSG"
              className="facebook-icon sociaMediaIcon"
              aria-label="Link Facebook"
              rel="noreferrer noopener"
            >
              <img alt="Facebook" src="/static/img/icons/facebook-icon.png" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/linkrewardssg"
              className="instagram-icon sociaMediaIcon"
              aria-label="Link Rewards"
              rel="noreferrer noopener"
            >
              <img alt="Instagram" src="/static/img/icons/instagram-icon.png" />
            </a>

            <a
              target="_blank"
              href="https://www.linkedin.com/company/ntuc-link-pte-ltd"
              className="sociaMediaIcon"
              aria-label="Link LinkedIn"
              rel="noreferrer noopener"
            >
              <img alt="LinkedIn" src="/static/img/icons/linkedin-icon.png" />
            </a>
          </SocialMediaLinks>
        </Col>
        <Col mb="md" display="flex" flexDirection="column" justifyContent="space-between">
          <Box>
            <Title>DOWNLOAD THE FAIRPRICE GROUP APP</Title>
            <StyledRow>
              <Col display="flex" alignSelf="center" justifyContent="center">
                <img
                  alt="Fairprice app"
                  className="fp-logo"
                  src="/static/img/common/fp-app-icon.png"
                />
              </Col>
              <Row display="flex" flexDirection={['column']}>
                <StyledCol>
                  <a href="https://app.adjust.com/y98n0zm" target="_blank" rel="noreferrer">
                    <img
                      alt="App"
                      className="app-logo"
                      src="/static/img/common/app-icon.png"
                      width="117"
                    />
                  </a>
                </StyledCol>
                <StyledCol>
                  <a href="https://app.adjust.com/y98n0zm" target="_blank" rel="noreferrer">
                    <img
                      alt="Fairprice app"
                      src="/static/img/common/google-play-icon.png"
                      width="117"
                    />
                  </a>
                </StyledCol>
              </Row>
            </StyledRow>
          </Box>
        </Col>
      </Row>
    </Contain>
  );
};
export default FooterNav;
