import { setCookie } from 'nookies';

const saveToStorage = (key, value) => {
  try {
    if (typeof localStorage !== 'undefined') {
      // Use localStorage if available
      localStorage.setItem(key, value);
    } else {
      // Fallback to cookies
      const cookieOptions = {
        secure:
          process.env.NODE_ENV === 'production' && /^https/.test(process.env.NEXT_PUBLIC_SITE_URL),
      };
      setCookie(null, key, value, cookieOptions);
    }
  } catch (err) {
    console.error('Error with storage:', err);
  }
};

export default saveToStorage;
