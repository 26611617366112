import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import { useRouter } from 'next/router';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

import { fetcher, getIdsFromCookies, headers, post } from '../../utils/fetch';

const AccountStateContext = createContext();
const AccountDispatchContext = createContext();

function setupSideEffects(obj) {
  const entries = Object.entries(obj);
  entries.forEach((entry) => {
    setCookie(null, entry[0], entry[1], {
      maxAge: 60 * 60 * 24,
      path: '/',
      secure:
        process.env.NODE_ENV === 'production' && /^https/.test(process.env.NEXT_PUBLIC_SITE_URL), // check NEXT_PUBLIC_SITE_URL to prevent triggering this flag on localhost production
    });
  });
}

function destroySideEffects() {
  const cookieOptions = { path: '/' };
  destroyCookie(null, 'accessId', cookieOptions);
  destroyCookie(null, 'authToken', cookieOptions);
  destroyCookie(null, 'accesstoken', cookieOptions);
  destroyCookie(null, 'userId', cookieOptions);
}

function accountReducer(state, action) {
  switch (action.type) {
    case 'LOADED': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'UPDATE': {
      const accountData = isObject(action.value)
        ? { ...state.accountData, ...action.value }
        : action.value;
      const { authToken } = parseCookies();
      return {
        ...state,
        accountData,
        isLoggedIn: !isEmpty(accountData) && Boolean(authToken),
      };
    }
    case 'UPDATE_PROFILE': {
      return {
        ...state,
        profile: action.value,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AccountProvider({ children }) {
  const initialState = {
    accountData: {},
    profile: null,
    isLoggedIn: false,
    loading: true,
  };
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const router = useRouter();

  const refreshUserData = useCallback(async () => {
    const { authToken } = parseCookies();
    if (authToken) {
      try {
        // using raw fetcher function to skip error handling in post function
        const res = await fetcher(`${process.env.NEXT_PUBLIC_API_URL}/v1/fetchUserDataForAid`, {
          method: 'POST',
          headers: headers(),
          body: JSON.stringify({
            body: getIdsFromCookies(),
            metaData: { client: 'website' },
          }),
        });

        if (res && res.code === '0000') {
          const { response } = res;
          setupSideEffects({
            accessId: response.accessId,
            userId: response.userId,
          });
          dispatch({
            type: 'UPDATE',
            value: response,
          });
        } else {
          logout({ silent: true });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    dispatch({ type: 'LOADED' });
  }, []);

  function login(code, redirectUrl) {
    if (redirectUrl) {
      const redirect = redirectUrl[0] !== '/' ? `/${redirectUrl}` : redirectUrl;
      router.push(redirect);
      return;
    }
    let url = '/profile?source=existing';

    if (code === 'LOGIN-S4000') {
      url = '/profile?source=new';
    }
    if (code === 'LOGIN-S5000') {
      url = '/profile?source=preissued';
    }

    router.push(url);
  }

  function logout(options = {}) {
    const { redirect, silent } = options;
    // using raw fetcher function to skip error handling in post function
    fetcher(`${process.env.NEXT_PUBLIC_API_URL}/v1/logout`, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({
        body: getIdsFromCookies(),
        metaData: { client: 'website' },
      }),
    }).then((json) => {
      destroySideEffects();
      if (silent) {
        return;
      } // silent just clears cookies but does not change route

      if (redirect) {
        window.location = redirect;
      } else {
        window.location = `/login?logout=${json.code === '0000' ? 'true' : 'expired'}`;
      }
    });
  }

  async function fetchUserProfile() {
    const res = await post('/v2/getUserProfile', {
      headers: headers(),
      body: {
        body: getIdsFromCookies(),
      },
    });
    if (res && res.code === '0000') {
      const { response } = res;
      dispatch({
        type: 'UPDATE_PROFILE',
        value: response,
      });
    }
  }

  useEffect(() => {
    refreshUserData();
  }, [refreshUserData]);

  return (
    <AccountStateContext.Provider
      value={{
        accountData: state.accountData,
        isLoggedIn: state.isLoggedIn,
        loading: state.loading,
        profile: state.profile,
        refreshUserData,
        login,
        logout,
        fetchUserProfile,
      }}
    >
      <AccountDispatchContext.Provider value={dispatch}>{children}</AccountDispatchContext.Provider>
    </AccountStateContext.Provider>
  );
}

function useAccountState() {
  const context = useContext(AccountStateContext);
  if (context === undefined) {
    throw new Error('useAccountState must be used within a AccountStateProvider');
  }
  return context;
}

function useAccountDispatch() {
  const context = useContext(AccountDispatchContext);
  if (context === undefined) {
    throw new Error('useAccountState must be used within a AccountDipatchProvider');
  }
  return context;
}

export { AccountProvider, useAccountState, useAccountDispatch };
