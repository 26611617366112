const colors = {
  purple: '#73308A',
  purpleLight: '#d8bcef',
  purpleBright: '#9823ff',
  black: '#1b1b1b',
  grey: '#444444',
  greyLight: '#c6c6c6',
  white: '#FFFFFF',
};

const space = {
  none: '0',
  xs: '0.25rem',
  sm: '0.5rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '2rem',
};

const breakpoints = ['33.875em', '40em', '52em', '64em', '80em', '110.625em']; // 542, 640, 832, 1024, 1280, 1770

const mediaQueries = {
  below: {
    xsmall: '@media screen and (max-width: 33.8125em)', //  541 and below
    small: '@media screen and (max-width: 39.9375em)', //  639 and below
    medium: `@media screen and (max-width: 51.9375em)`, // 831 and below
    large: `@media screen and (max-width: 63.9375em)`, // 1023 and below
    xlarge: `@media screen and (max-width: 79.9375em)`, // 1279 and below
    xxlarge: `@media screen and (max-width: 110.5625em)`, // 1769 and below
  },
  above: {
    xsmall: `@media screen and (min-width: ${breakpoints[0]})`, // 542 and above
    small: `@media screen and (min-width: ${breakpoints[1]})`, // 640 and above
    medium: `@media screen and (min-width: ${breakpoints[2]})`, // 832 and above
    large: `@media screen and (min-width: ${breakpoints[3]})`, // 1024 and above
    xlarge: `@media screen and (min-width: ${breakpoints[4]})`, // 1280 and above
    xxlarge: `@media screen and (min-width: ${breakpoints[5]})`, // 1770 and above
  },
  only: {
    xsmall: `@media screen and (min-width: ${breakpoints[0]}) and (max-width: 39.9375em)`, // 542 to 639
    small: `@media screen and (min-width: ${breakpoints[1]}) and (max-width: 51.9375em)`, // 640 to 831
    medium: `@media screen and (min-width: ${breakpoints[2]}) and (max-width: 63.9375em)`, // 832 to 1023
    large: `@media screen and (min-width: ${breakpoints[3]}) and (max-width: 79.9375em)`, // 1024 to 1279
    xlarge: `@media screen and (min-width: ${breakpoints[4]}) (max-width: 110.5625em)`, // 1280 to 1769
  },
};

const theme = {
  colors,
  space,
  breakpoints,
  mediaQueries,
};

export default theme;
