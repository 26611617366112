import { createContext, useContext, useEffect, useReducer } from 'react';
import { useRouter } from 'next/router';

const AlertContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'SET_ALERT': {
      return {
        ...state,
        visible: true,
        message: action.value,
      };
    }
    case 'QUEUE_ALERT': {
      return {
        ...state,
        queue: state.queue.concat(action.value),
      };
    }
    case 'SHIFT_ALERT': {
      return {
        visible: state.queue.length > 0,
        message: state.queue.shift(),
        queue: [...state.queue],
        dialog: state.dialogQueue.shift(),
        dialogQueue: [...state.dialogQueue],
      };
    }
    case 'SHOW_ALERT': {
      return {
        ...state,
        visible: true,
      };
    }
    case 'CLOSE_ALERT': {
      return {
        ...state,
        visible: false,
      };
    }
    case 'RESET_ALERT': {
      return {
        queue: [],
        visible: false,
        message: null,
        dialogQueue: [],
        dialog: null,
      };
    }
    case 'QUEUE_DIALOG': {
      return {
        ...state,
        dialogQueue: state.dialogQueue.concat(action.value),
      };
    }
    case 'CLOSE_DIALOG': {
      return {
        ...state,
        dialog: null,
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function AlertProvider(props) {
  const initialState = {
    visible: false,
    message: null,
    queue: [],
    dialog: null,
    dialogQueue: [],
  };
  const [state, dispatch] = useReducer(userReducer, initialState);
  const Router = useRouter();

  useEffect(() => {
    const { logout } = Router.query;
    if (logout) {
      let msg;
      if (logout === 'true') {
        msg = 'You have successfully logged out.';
      }
      if (logout === 'expired') {
        msg = 'We tried to logout but your session may already have expired.';
      }
      dispatch({
        type: 'QUEUE_ALERT',
        value: msg,
      });
    }
  }, [Router, dispatch]);

  return <AlertContext.Provider value={[state, dispatch]} {...props} />;
}

function useAlert() {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
}

export { AlertProvider, useAlert };
