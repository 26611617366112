import SearchIcon from './Search/SearchIcon';
import SC from './headerElements';

const NavToggle = ({ navOpen, toggleNav, toggleSearch, searchOpen }) => {
  const active = navOpen && `opened`;
  return (
    <SC.Flex>
      <SearchIcon
        className="head-visible-mobile"
        searchOpen={searchOpen}
        toggleSearch={toggleSearch}
        testid="search"
      />
      <SC.Button aria-label="Menu" onClick={toggleNav} data-testid="toggleNav" navOpen={navOpen}>
        <span className={`toggle-nav ${active}`}></span>
      </SC.Button>
    </SC.Flex>
  );
};

export default NavToggle;
