import Dialog from '@mui/material/Dialog';
import styled, { css } from 'styled-components';

import { theme } from './GlobalStyles';
import { Flex } from './Grid';

const ButtonModalClose = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  span {
    font-size: 2.25rem;
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    background: ${(props) => props.alt && 'rgba(0, 0, 0, 0.8)'};
  }
  .MuiDialog-paper {
    border-radius: 0.5rem;
    max-width: 50vw;
    padding: 1.5rem;
    box-sizing: content-box;
    width: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 5rem);
    ${(props) =>
      props.alt &&
      css`
        background-color: transparent;
        color: #fff;
        text-align: center;
        h2 {
          color: #fff;
        }
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: none;
      `}

    ${theme.mediaQueries.above.small} {
      max-width: 75vw;
      box-sizing: border-box;
      min-width: 39.375rem;
      margin: 0 auto;
    }
  }
`;

const AlertDialog = ({
  title,
  children,
  isOpen,
  onDismiss,
  ariaLabel,
  alt = '',
  className,
  testid,
}) => (
  <StyledDialog
    className={className}
    alt={alt}
    open={isOpen}
    onClose={onDismiss}
    data-testid={testid}
    aria-labelledby={ariaLabel}
  >
    {!alt && (
      <Flex justifyContent="flex-end" m="-1rem">
        <ButtonModalClose data-testid="close-button" className="close-button" onClick={onDismiss}>
          <span aria-hidden>×</span>
        </ButtonModalClose>
      </Flex>
    )}
    {title && (
      <h3
        css={`
          margin-top: 0;
        `}
      >
        {title}
      </h3>
    )}
    <div
      css={`
        overflow-y: auto;
        height: 100%;
        li {
          margin-bottom: 0.5rem;
        }
      `}
    >
      {children}
    </div>
  </StyledDialog>
);

export default AlertDialog;
