import { css } from 'styled-components';

const typography = ({ theme }) => css`
  body {
    font-family: 'Overpass', sans-serif;
    color: ${theme.colors.grey};
    font-weight: 400;
  }

  a {
    color: ${theme.colors.purple};
    font-weight: 600;
    text-decoration: none;
  }

  h1,
  .h1 {
    font-size: 2.25rem; /* 36px */
    font-weight: 800;
    line-height: 1.222222222;
    color: ${theme.colors.black};
  }

  h2,
  .h2 {
    font-size: 1.5rem; /* 24px */
    font-weight: 700;
    line-height: 1.333333333;
    color: ${theme.colors.black};
  }

  h3,
  .h3 {
    font-size: 1rem; /* 16px */
    font-weight: 700;
    line-height: 1.5;
    color: ${theme.colors.black};
  }

  h4,
  .h4 {
    margin-bottom: 0.5rem;

    + p {
      margin-top: 0;
    }
  }

  .subtitle {
    font-weight: 500;
  }
  small,
  .small {
    font-size: 0.875rem; /* 14px */
    font-weight: 400;
    line-height: 1.428571429;
    color: ${theme.colors.grey};
  }

  ${theme.mediaQueries.above.small} {
    body {
      font-size: 1.25rem;
    }
    h1,
    .h1 {
      font-size: 2.8125rem; /* 45px */
    }
    h2,
    .h2 {
      font-size: 1.875rem; /* 30px */
    }
    h3,
    .h3 {
      font-size: 1.25rem; /* 20px */
    }
    small,
    .small {
      font-size: 1rem;
    }
  }
`;

export default typography;
