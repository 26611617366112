import styled, { css } from 'styled-components';

import { theme } from '../GlobalStyles';
import { Box, Col, Contain, Flex, Row } from '../Grid';

import Masthead from './Masthead';
import NavToggle from './NavToggle';

const SC = {};

SC.Header = styled.header`
  box-shadow: 0 0.25rem 0.375rem 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 100%;
  z-index: 8;
  transition: transform 250ms ease-in-out;
  background-color: #fff;
  .head-visible-mobile {
    display: block;
  }
  .head-hidden-mobile {
    display: none;
  }
  ${(props) =>
    props.headerHidden &&
    css`
      transform: translateY(-100%);
    `}
  &.is-maintenance nav {
    top: 0;
  }
  .styled-flex {
    flex-direction: column;
  }
  img.logo-mobile {
    width: 8.75rem;
    padding: 1rem 0;
  }
  .logo-desktop {
    width: 11.25rem !important;
    display: none;
  }

  ${theme.mediaQueries.above.xlarge} {
    .head-visible-mobile {
      display: none;
    }
    .head-hidden-mobile {
      display: block;
    }
    .styled-flex {
      flex-flow: wrap;
      justify-content: flex-end;
      width: calc(100% - 15.3125rem);
      align-items: flex-end;
      padding: 0 2rem 0 10rem;
      a {
        padding-right: 3.125rem;
      }
    }
    .logo-desktop {
      display: block;
    }
  }
`;

SC.SearchBox = styled(Box)`
  position: absolute;
  left: 0;
  width: 100%;
  background: #ededed;
  padding: 3.125rem 0;
  transform: translateY(0);
  transition: all 0.3s;
`;

SC.SearchWrapper = styled(Box)`
  padding-right: 1rem;
  margin-right: 1.25rem;
  border-right: 0.0625rem solid #cccccc;
  ${theme.mediaQueries.above.xlarge} {
    padding: 0 0 0 2rem;
    margin-right: 0;
    border-right: none;
    border-left: 0.0625rem solid #cccccc;
  }
`;

SC.StyledSearchIcon = styled(Box)`
  ${(props) => props.disabled && 'pointer-events: none;'}
  &.search-icon {
    width: 1.9375rem;
    height: 1.875rem;
    position: relative;
    cursor: pointer;
    margin: auto;
  }

  .search-line {
    width: 0.625rem;
    height: 0.2125rem;
    background: black;
    display: inline-block;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1.5625rem;
    left: 1.25rem;
    transition: all 0.3s ease-in 0s;
  }

  .search-circle {
    width: 1.5rem;
    height: 1.5rem;
    border: 0.25rem solid black;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transition: top 0.3s ease-in 0s, left 0.3s ease-in 0s, border-radius 0.3s ease-in 0s,
      border-width 0.3s ease-in 0s, width 0.3s ease-in 0s, height 0.3s ease-in 0s,
      background-color 0.1s ease-in 0s;
    background-color: #fff;
  }

  &.active .search-line {
    width: 1.9375rem;
    left: 0;
    top: 0.75rem;
    background: ${theme.colors.purple};
    transition: all 0.3s ease-out 0s;
  }

  &.active .search-circle {
    width: 0;
    height: 0;
    background: ${theme.colors.purple};
    border-width: 0;
    top: 1.125rem;
    left: 1.125rem;
    transition: top 0.3s ease-in 0s, left 0.3s ease-in 0s, border-radius 0s ease-in 0.3s,
      border-width 0.3s ease-in 0s, width 0.3s ease-in 0s, height 0.3s ease-in 0s,
      background-color 0s ease-in 0.3s;
  }

  &.active .second-line {
    transform: rotate(135deg);
  }
`;

SC.StyledShade = styled(Box)`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 2;
  opacity: 1;
  pointer-events: all;
`;

SC.StyledContain = styled(Contain)`
  padding: 1rem;
  box-shadow: 0 0.25rem 0.375rem 0 rgb(0 0 0 / 5%);

  ${theme.mediaQueries.above.xlarge} {
    padding: 0 1rem;
    box-shadow: none;
  }
`;

SC.MaintenanceBar = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.purple};
  text-align: center;
  padding: 0.5rem 0;
  color: #ffffff;
  font-size: 0.875rem;
  min-height: 4.9375rem;
  a {
    color: white;
    font-weight: normal;
    text-decoration: underline;
  }

  ${theme.mediaQueries.above.xlarge} {
    min-height: 0;
  }
`;

SC.FauxHeader = styled.div`
  height: ${(props) => props.height}px;
`;

SC.Button = styled.button.attrs({
  type: 'button',
  ariaLabel: 'Open main navigation',
})`
  ${(props) => props.disabled && 'pointer-events: none;'}

  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  width: 2.125rem;

  .toggle-nav {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 1.625rem;
    height: 0.25rem;
    background: black;
    border: none;
    margin: 0 1rem 0 0;
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 2.125rem;
      height: 0.25rem;
      background-color: black;
      position: absolute;
      display: block;
      color: red;
      transition: all 0.3s linear;
    }

    &::before {
      top: -250%;
      left: 0%;
      transform: rotateZ(0deg);
      transform-origin: left top;
    }

    &::after {
      bottom: -250%;
      left: 0%;
      transform: rotateZ(0deg);
      transform-origin: left bottom;
    }

    &:focus {
      outline: none;
    }
    &.opened {
      margin-left: 0.25rem;
      background-color: transparent;
      &::before {
        content: '';
        top: -0.625rem;
        left: 0;
        transform: rotateZ(45deg);
        background-color: black;
        width: 2.125rem;
      }

      &::after {
        content: '';
        transform: rotateZ(-45deg);
        top: 0.75rem;
        left: 0;
        width: 2.125rem;
        background-color: black;
      }
    }
  }
  ${theme.mediaQueries.above.xlarge} {
    display: none;
  }
`;

SC.MobileLogin = styled(Row)`
  font-size: 0.875rem;
  display: flex;
  justify-content: space-around;
  margin: 0;

  ul.meta {
    width: 100%;
    a.faq {
      margin-bottom: 1.5rem;
      color: ${theme.colors.black};
      font-weight: 400;
    }
  }
`;

SC.StyledLi = styled.li`
  a,
  button {
    font-weight: 400;
    color: ${theme.colors.purple} !important;
    font-size: 1.1875rem;
    line-height: 1.2;
    background: #ffffff;
    border: 0.0625rem solid ${theme.colors.purple};
    border-radius: 1.5625rem;
    text-align: center;
    width: 100%;
    padding: 0.5rem !important;
    margin-bottom: 0.5rem;
    &.is-active,
    &:hover {
      background: ${theme.colors.purple} !important;
      color: #ffffff !important;
    }
  }

  ${theme.mediaQueries.above.xlarge} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 11.25rem;
    a,
    button {
      margin-bottom: 0;
    }
    &[data-loggedin='true'] {
      a {
        border: none;
        font-weight: 500;
        &:hover {
          background: transparent;
          color: ${theme.colors.purple};
        }
      }
    }
  }
`;

const SubNav = styled.div`
  position: absolute;
  z-index: 11;
  background: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 0 1.5rem;
  display: none;
  &[data-loggedin='true'] {
    margin: 3.25rem 0 0 2rem;
    padding: 0.3125rem 2rem;
    a {
      color: ${theme.colors.black};
    }
  }
`;

SC.StyledMasthead = styled.div`
  background: #fff;

  .logo {
    height: 1.5625rem;
    min-width: 4.0625rem;
    display: block;
  }
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.8125rem;

    li:hover ${SubNav} {
      display: block;
    }
  }
  .logo-link {
    margin: 0;
  }
  .meta {
    list-style: none;
    padding: 0;
    margin: 0;

    a.faq {
      color: #000000;
    }
  }
  ${theme.mediaQueries.above.small} {
    .logo {
      height: 2.5rem;
    }
  }

  ${theme.mediaQueries.above.xlarge} {
    .meta {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 1rem;
      li + li {
        margin: 0 0 0 1rem;
      }
      a.faq {
        font-size: 1.125rem;
        margin-left: 0.25rem;
        font-weight: 500;
        margin-left: 0.5rem;
      }
      a.faq:hover {
        color: ${theme.colors.purple};
      }
    }
    .header-inner {
      height: 5.9612rem;
    }
    .logo {
      height: 2.9375rem;
    }
  }
`;

SC.Divider = styled(Box)`
  border-bottom: 0.0625rem solid ${theme.colors.purple};
  padding-bottom: 1rem;
  margin: 0 -1rem 1.625rem;
`;

const SubNavMobile = styled.div`
  display: none;

  ul {
    margin: 0;
    padding: 0 0 0.75rem;
    display: block;
    background: #fff;
  }
  a {
    justify-content: flex-start;
    text-align: left;
    padding: 0.25rem 0;
    font-weight: 500;
  }

  ${theme.mediaQueries.above.xlarge} {
    display: none;
    position: absolute;
    min-width: 100%;
    ul {
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
    }
    a {
      padding: 0.25rem 1.5rem;
      white-space: nowrap;
    }
  }
`;

SC.Nav = styled.nav`
  display: none;
  width: 100%;
  background: #fff;
  padding: 1rem;
  transform: translateX(-100%);
  transition: border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  z-index: 9;
  &.is-open {
    display: block;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 1rem 0 1.5rem;
    transform: translateX(0);
  }
  span.newTag {
    padding: 0.25rem 0.25rem 0.125rem;
    background: #3df0bf;
    color: ${theme.colors.white};
    border-radius: 0.25rem;
    top: -1rem;
    position: relative;
    font-size: 0.875rem;
  }
  ul {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.2;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin-bottom: 1rem;
  }
  a {
    display: block;
    position: relative;
    white-space: nowrap;
  }
  .main-nav a {
    color: ${theme.colors.black};
  }

  .main-nav a.is-active {
    color: ${theme.colors.purple};
  }
  .loggedin.has-subnav a {
    padding-right: 0;
    color: ${theme.colors.purple};
    &::after {
      content: '';
      border-color: transparent ${theme.colors.purple} ${theme.colors.purple} transparent;
    }
    &:hover {
      color: ${theme.colors.black};
      &::after {
        content: '';
        border-color: transparent ${theme.colors.black} ${theme.colors.black} transparent;
      }
    }
    display: none;
    ${theme.mediaQueries.above.large} {
      display: block;
    }
  }
  .has-subnav > a::after {
    content: '';
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: -0.25rem 0 0 0.75rem;
    width: 0.75rem;
    height: 0.75rem;
    border-style: solid;
    border-color: transparent ${theme.colors.black} ${theme.colors.black} transparent;
    border-width: 0 0.125rem 0.125rem 0;
    transform: rotateZ(45deg);
    transform-origin: 50%;
    ${theme.mediaQueries.above.xlarge} {
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  ${theme.mediaQueries.below.xlarge} {
    a {
      font-weight: 400;
    }

    .has-subnav.is-active > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.5rem;
      border-bottom: 0.25rem solid ${theme.colors.purple};
      margin-bottom: 0.5rem;
    }
    .has-subnav.is-active a::after {
      transform: rotateZ(225deg);
      top: 0.1875rem;
    }
    .has-subnav.is-active ${SubNavMobile} {
      display: block;
    }
    .has-subnav li {
      margin-bottom: 0.5rem;
      a {
        color: ${theme.colors.purple};
      }
    }
    .has-subnav a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 0.5rem;
    }
  }

  ${theme.mediaQueries.above.xlarge} {
    display: block;
    padding: 0;
    position: static;
    transform: none;
    &.is-open {
      display: block;
      transform: translateX(0);
      box-shadow: 0 4px 6px 0 rgb(0 0 0 / 5%);
      padding: 0;
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: end;
      li {
        position: relative;
        margin-bottom: 0;
        padding: 1.5rem 0;
        ul li {
          padding: 0.25rem;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        a:hover {
          color: ${theme.colors.purple};
          &::after {
            content: '';
            border-color: transparent ${theme.colors.purple} ${theme.colors.purple} transparent;
          }
        }
      }
    }

    .has-subnav:hover ${SubNavMobile} {
      display: block;
    }
    .has-subnav:last-child ${SubNavMobile} {
      right: 0;
    }
    .head-visible-mobile {
      display: none;
    }
  }
`;

SC.StyledCol = styled(Col)`
  padding-top: 0.5rem;
  a {
    border: none;
    padding: 0 0.5rem;
    &:hover {
      border: none;
      background: transparent;
    }
    img {
      width: 7.8125rem;
      ${theme.mediaQueries.above.xlarge} {
        width: 9.375rem;
      }
    }
  }
  ${theme.mediaQueries.above.xlarge} {
    a {
      padding: 0 1rem 0 0;
    }
  }
`;

const headerElements = {
  ...SC,
  SubNav,
  SubNavMobile,
  Contain,
  Box,
  Row,
  Col,
  Masthead,
  NavToggle,
  Flex,
};

export default headerElements;
