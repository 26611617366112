import { Fragment, useEffect } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { useRouter } from 'next/router';

import saveToStorage from '../utils/saveToStorage';

import { useAccountState } from './context/account-context';
import { useAlert } from './context/alert-context';
import Alert from './Alert';
import AlertDialog from './AlertDialog';
import Button from './Button';
import Footer from './Footer';
import { Contain } from './Grid';
import Header from './Header';

const Layout = ({ children, noLayout }) => {
  const [state, dispatch] = useAlert();
  const THREE_DAYS = 1000 * 60 * 4320;
  const { isLoggedIn, logout } = useAccountState();
  const { query } = useRouter();
  const router = useRouter();
  const hideLayout = noLayout || Object.keys(query).includes('noLayout');

  const saveQueryParams = () => {
    const queryParamsToSave = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ];

    queryParamsToSave.forEach((param) => {
      if (query[param]) {
        saveToStorage(param, query[param]);
      }
    });
  };

  const handleRouteChangeComplete = () => {
    dispatch({ type: 'SHIFT_ALERT' });
  };

  useEffect(() => {
    saveQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onIdle = () => {
    dispatch({
      type: 'QUEUE_ALERT',
      value: 'Session time out. Please login again.',
    });
    logout();
  };

  if (state.visible) {
    window.scrollTo(0, 0);
  }

  return (
    <Fragment>
      {isLoggedIn && <IdleTimerProvider onIdle={onIdle} debounce={250} timeout={THREE_DAYS} />}
      {!hideLayout && <Header />}
      {state.visible && (
        <Contain>
          <Alert onDismiss={() => dispatch({ type: 'CLOSE_ALERT' })}>{state.message}</Alert>
        </Contain>
      )}
      {state.dialog && (
        <AlertDialog
          alt="alt"
          ariaLabel={state.dialog.title}
          isOpen
          onDismiss={() => dispatch({ type: 'CLOSE_DIALOG' })}
        >
          <img alt="" src={state.dialog.img} />
          <h2>{state.dialog.title}</h2>
          {state.dialog.text && <p>{state.dialog.text}</p>}
          {state.dialog.content && <p>{state.dialog.content}</p>}
          <Button onClick={() => dispatch({ type: 'CLOSE_DIALOG' })}>
            {state.dialog.button || 'Okay, got it'}
          </Button>
        </AlertDialog>
      )}
      {children}
      {!hideLayout && <Footer />}
    </Fragment>
  );
};

export default Layout;
