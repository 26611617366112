import styled from 'styled-components';

import { theme } from '../GlobalStyles';
import { Box } from '../Grid';

import FooterNav from './FooterNav';

const StyledFooter = styled.footer`
  margin-top: auto;

  ${theme.mediaQueries.above.small} {
    .toggle-nav {
      display: none;
    }
    .header-inner {
      height: 5rem;
    }
    .logo {
      height: 3.75rem;
    }
  }
`;

const FooterInner = styled.footer`
  border-top: 1px solid #959595;
  background: #73308a;
  padding-top: 3.75rem;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-position: bottom;
  background-size: auto;
  padding-bottom: 1rem;
  background-size: 100%;
`;
const StyledBox = styled(Box)`
  display: flex;
  color: white;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 1rem 1rem 0;
  font-size: 1rem;
  font-weight: 400;
  margin: auto;
  max-width: 20rem;
  background: #73308a;

  ${theme.mediaQueries.only.xlarge} {
    padding-bottom: 0.5rem;
  }
  ${theme.mediaQueries.above.xxlarge} {
    padding-bottom: 1.5625rem;
  }
`;
const Footer = () => (
  <StyledFooter data-testid="footer">
    <FooterInner>
      <FooterNav />
      <StyledBox>© {new Date().getFullYear()} NTUC Link. All rights reserved.</StyledBox>
    </FooterInner>
  </StyledFooter>
);

export default Footer;
