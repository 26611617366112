import styled from 'styled-components';

import { theme } from '../../GlobalStyles';

const SearchContainer = styled.div`
  height: 100%;

  .search-toggle {
    border: none;
    background: transparent;
    height: 100%;
    position: relative;
    .icon {
      border-radius: 100%;
      width: 2.75rem;
      height: 2.75rem;
      background: #f7f5f8;
    }
    svg {
      fill: #040404;
      opacity: ${(props) => (props.searchOpen ? '0' : '1')};
    }

    &.is-open {
      svg {
        opacity: 0;
      }
      &::before,
      &::after {
        content: '';
        width: 1.125rem;
        height: 0.125rem;
        background: #040404;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotateZ(-45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotateZ(45deg);
      }
    }
  }

  ${theme.mediaQueries.above.large} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 ${theme.space.lg};
    .search-toggle {
      display: none;
    }
  }
`;

const SearchMain = styled.div`
  display: none;
  width: 100%;
  padding: 0 1.25rem;

  &.is-active {
    display: block;
    top: 0;
    left: 0;
    position: relative;
  }

  ${theme.mediaQueries.above.large} {
    display: block;
    top: 0;
    left: 0;
    position: relative;
    max-width: 80rem;
  }

  .search-form {
    position: relative;
    display: flex;
    button {
      background: transparent;
      width: 1.5rem;
      border: none;
      border-bottom: 0.0625rem solid purple;
      color: transparent;
      cursor: pointer;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    right: -0.5rem;
    width: 2rem;
    height: auto;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const SearchInput = styled.input.attrs({ type: 'text', name: 'q' })`
  border: none;
  border-bottom: 0.0625rem solid ${theme.colors.purple};
  width: 100%;
  font-size: 1.0625rem;
  background: transparent;
  border-radius: 0;

  ${theme.mediaQueries.above.large} {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &:focus {
    outline: none;
  }
`;

export { SearchContainer, SearchMain, SearchInput };
