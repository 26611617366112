import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import Router from 'next/router';

import { getMaintenance } from '../../services/api';
import { useAlert } from '../context/alert-context';

import SC from './headerElements';
import Nav from './Nav';
import Search from './Search';

const Header = () => {
  const [, dispatch] = useAlert();
  const [maintenanceData, setMaintenanceData] = useState(null);
  const [headerHidden, setHeaderHidden] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState();
  const [searchOpen, setSearchOpen] = useState(false);
  const headerRef = useRef();
  const scrollRef = useRef(0);
  const toggleNav = () => {
    setSearchOpen(false);
    setNavOpen(!navOpen);
  };
  const toggleSearch = () => {
    setNavOpen(false);
    setSearchOpen(!searchOpen);
  };
  const getCurrentHeight = () => {
    return headerRef.current && headerRef.current.getBoundingClientRect().height;
  };

  Router.events.on('routeChangeComplete', () => {
    window.scrollTo(0, 0);
    setHeaderHidden(false);
  });

  useEffect(() => {
    if (navOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [navOpen]);

  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const response = await getMaintenance();
        const list = response.data.list || [];
        if (list.length > 0) {
          setMaintenanceData(list);
        }
      } catch {
        dispatch({
          type: 'SET_ALERT',
          value: 'Something went wrong. Please try again later.',
        });
      }
    };
    fetchMaintenanceData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resizeHandlerDebounced = debounce(() => {
    const height = getCurrentHeight();
    setHeaderHeight(height);
  }, 250);

  const scrollHandlerThrottled = throttle(() => {
    const { scrollTop: currentScrollTop } = document.documentElement || document.body;
    setHeaderHidden(scrollRef.current < currentScrollTop && currentScrollTop > 100); // if scrolling down, hide header, else show
    scrollRef.current = currentScrollTop;
  }, 250);

  useEffect(() => {
    window.addEventListener('resize', resizeHandlerDebounced);
    window.addEventListener('scroll', scrollHandlerThrottled);
    return () => {
      window.removeEventListener('resize', resizeHandlerDebounced);
      window.removeEventListener('scroll', scrollHandlerThrottled);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const height = getCurrentHeight();
    setHeaderHeight(height);
  }, [maintenanceData, navOpen]);

  return (
    <>
      <SC.Header
        ref={headerRef}
        className={maintenanceData ? 'is-maintenance' : ''}
        headerHidden={headerHidden}
        data-testid="header-component"
      >
        {maintenanceData && (
          <SC.MaintenanceBar>
            <SC.Contain>
              <div dangerouslySetInnerHTML={{ __html: maintenanceData[0].message }} />
            </SC.Contain>
          </SC.MaintenanceBar>
        )}

        <SC.StyledContain>
          <SC.Masthead
            navOpen={navOpen}
            toggleNav={toggleNav}
            searchOpen={searchOpen}
            toggleSearch={toggleSearch}
          />
          {navOpen && <SC.Divider className="head-visible-mobile" />}
          <Nav
            navOpen={navOpen}
            toggleNav={toggleNav}
            searchOpen={searchOpen}
            toggleSearch={toggleSearch}
          />
        </SC.StyledContain>
        {searchOpen && (
          <SC.SearchBox>
            <Search searchOpen={searchOpen} toggleSearch={toggleSearch} />
          </SC.SearchBox>
        )}
      </SC.Header>
      <SC.FauxHeader height={headerHeight} />
      {searchOpen && <SC.StyledShade />}
    </>
  );
};

export default Header;
