import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { SearchContainer, SearchInput, SearchMain } from './styles';

const Search = ({ searchOpen, toggleSearch }) => {
  const router = useRouter();
  const nodeRef = useRef();
  const inputRef = useRef();
  const d1 =
    '3.168-7.754 7.708.32 2.957 2.762 5.385 5.63 5.913 2.124.317 4.142-.317 5.63-1.584l.318.317v.845l4.461 4.434a1.158 1.158 0 0 0 1.593 0 1.143 1.143 0 0 0 ';
  const d2 =
    '0-1.584l-4.355-4.54zm-6.372 0c-2.656 0-4.78-2.113-4.78-4.752 0-2.64 2.124-4.752 4.78-4.752 2.655 0 4.78 2.112 4.78 4.752 0 2.64-2.125 4.751-4.78 4.751z';

  const handleSubmit = (e) => {
    toggleSearch();
    e.preventDefault();
    const value = inputRef.current.value.trim();
    if (value !== '') {
      router.push(`/search?q=${value}`);
    }
  };

  useEffect(() => {
    if (searchOpen) {
      inputRef.current.focus();
    }
  }, [searchOpen]);

  return (
    <SearchContainer data-testid="search-component" ref={nodeRef}>
      <SearchMain className={searchOpen ? 'is-active' : ''}>
        <form className="search-form" onSubmit={handleSubmit} action="/search" method="get">
          <SearchInput ref={inputRef} placeholder="Search" />
          <button type="submit">
            <svg
              className="search-icon icon-svg"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d={`M16.326 14.56h-.85l-.318-.318a6.932 6.932 0 0 0 1.593-5.596c-.53-2.956-2.974-5.279-5.948-5.596-4.46-.528-8.285 ${d1} ${d2}`}
              />
            </svg>
          </button>
        </form>
      </SearchMain>
    </SearchContainer>
  );
};

export default Search;
