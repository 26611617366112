import React, { Children } from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';

const ActiveLink = ({ router, children, ...props }) => {
  const child = Children.only(children);

  let className = child.props.className || null;

  if (router?.pathname === props.href) {
    className = `${className !== null ? className : ''} is-active`.trim();
  }
  return (
    <Link {...props} legacyBehavior>
      {React.cloneElement(child, { className })}
    </Link>
  );
};

export default withRouter(ActiveLink);
