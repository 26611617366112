import styled from 'styled-components';

import { theme } from './GlobalStyles';

const StyledAlert = styled.div`
  padding: 0.375rem 0.875rem;
  display: flex;
  align-items: flex-start;
  margin: ${theme.space.xl} 0;
  background: #f2e9fa;
  color: ${theme.colors.purple};
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.25;

  .content {
    flex: 1;
    margin: 0.5625rem 1rem 0 0.625rem;
  }

  .close {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0.625rem;
    img {
      display: block;
    }
  }
`;

const Alert = ({ children, onDismiss }) => (
  <StyledAlert data-testid="alert-component">
    <div className="content">{children}</div>
    <button type="button" aria-hidden className="close" onClick={onDismiss}>
      <img src="/static/img/icons/cancel.svg" alt="Close" />
    </button>
  </StyledAlert>
);

export default Alert;
