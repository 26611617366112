import Router from 'next/router';
import { parseCookies } from 'nookies';

const https = require('https');

const agent = new https.Agent({
  keepAlive: true,
  keepAliveMsecs: 1500,
  maxSockets: 50,
});

const headersParams = {
  'Content-Type': 'application/json',
  'Accept-Language': 'en',
};
class HTTPError extends Error {
  constructor({ status, statusText }) {
    super();
    // eslint-disable-next-line no-console
    console.error(`HTTPError: ${status} - ${statusText}`);
    this.code = status;
    this.message = statusText;
  }
}

async function fetcher(input, init) {
  let response;
  try {
    response = await fetch(input, init);
  } catch (error) {
    throw Error('Network error');
  }

  if (!response.ok) {
    throw new HTTPError(response.status);
  }
  return response.json();
}

function handleInvalidToken(response) {
  if (response.code === 'S001') {
    if (Router.router) {
      const asPathSplit = Router.router.asPath.split('redirect=');
      const asPath = asPathSplit[asPathSplit.length - 1];

      Router.push(`/login?redirect=${asPath}`);
    }
  }
  return response;
}

function headers(ctx) {
  const { authToken } = parseCookies(ctx);
  return {
    ...headersParams,
    ...(authToken && { Authorization: authToken }),
  };
}

function getIdsFromCookies(ctx) {
  const { accessId, userId } = parseCookies(ctx);
  return {
    accessId,
    userId,
  };
}

function fetchGet(url, options) {
  const init = {
    method: 'GET',
    headers: { ...headersParams },
    ...options,
  };

  return fetcher(`${process.env.NEXT_PUBLIC_API_URL}${url}`, init).then(handleInvalidToken);
}

function fetchPost(url, options) {
  const init = {
    method: 'POST',
    headers: { ...headersParams },
    body: {
      body: {},
    },
    ...options,
  };

  init.body.metaData = { client: 'website' };
  init.body = JSON.stringify(init.body);

  return fetcher(`${process.env.NEXT_PUBLIC_API_URL}${url}`, init).then(handleInvalidToken);
}

function fetchRms(endPoint, method, queryParams) {
  const { authToken } = parseCookies();
  return fetcher(`${process.env.NEXT_PUBLIC_API_URL}/v1/rms`, {
    agent,
    method: 'post',
    headers: {
      ...headersParams,
      ...(authToken && { Authorization: authToken }),
    },
    body: JSON.stringify({
      body: {
        endPoint,
        method,
        ...(queryParams && { queryParams }),
      },
    }),
  });
}

function get(url, options) {
  return fetchGet(url, options);
}

function post(url, options) {
  return fetchPost(url, options);
}

function rmsGet(url, queryParams) {
  return fetchRms(url, 'get', queryParams);
}

function rmsPost(url, queryParams) {
  return fetchRms(url, 'post', queryParams);
}

export { headers, getIdsFromCookies, get, post, rmsGet, rmsPost, fetcher };
