/* Desktop Nav */
/* eslint-disable react/jsx-no-target-blank */
import Link from 'next/link';

import SC from './headerElements';
import NavToggle from './NavToggle';

const Masthead = ({ navOpen, toggleNav, toggleSearch, searchOpen }) => (
  <SC.StyledMasthead className="head-visible-mobile">
    <div className="header-inner">
      <Link href="/">
        <img alt="NTUC Link" className="logo-mobile" src="/static/img/common/logo.svg" />
      </Link>
      <NavToggle
        navOpen={navOpen}
        toggleNav={toggleNav}
        toggleSearch={toggleSearch}
        searchOpen={searchOpen}
      />
    </div>
  </SC.StyledMasthead>
);

export default Masthead;
