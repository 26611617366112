import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

import typography from './_typography';
import theme from './theme';

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}
  ${typography}


  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    line-height: 1.5;

    ${theme.mediaQueries.below.small} {
      &.no-scroll {
        overflow: hidden;
      }
    }

  }

  #__next {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  a {
    cursor: pointer;
  }

  figure {
    margin: 0;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }

  .scrollable-x {
    width: 100%;
    overflow-x: auto;
  }

  .table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;

    th, td {
      padding: 1.25rem 1.5rem;
    }

    thead th {
      background-color: ${theme.colors.purple};
      color: #fff;
      &:first-child {
        border-radius: 0.25rem 0 0 0.25rem;
      }
      &:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }


    tr:nth-child(even) {
      background: #f1eaf9;
    }

    tbody th {
      background-color: ${theme.colors.greyLight};
      font-size: 0.875rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-transform: uppercase;
      color: ${theme.colors.grey};
    }
  }

   hr {
    border-width: 1px 0 0;
    border-style: solid;
    border-color: #dadada;
    margin: 2rem 0;
  }

  .grecaptcha-badge { visibility: hidden; }

  .contain {
    max-width: 75rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .object-cover {
    object-fit: cover;
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: #666;
  }
  .text-center {
    text-align: center;
  }
  .visually-hidden {
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  hr {
    border-color: ${theme.colors.purpleLight}
  }

  ${theme.mediaQueries.below.small} {
    .hidden-mobile {
      display: none;
    }
    .visually-hidden-mobile {
      clip: rect(0, 0, 0, 0);
      clip-path: inset(50%);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      position: absolute;
    }
  }

  ${theme.mediaQueries.above.small} {
    .visible-mobile {
      display: none !important;
    }
  }

`;

export default GlobalStyles;

export { theme };
