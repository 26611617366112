import Link from 'next/link';
import styled, { css } from 'styled-components';
import { flexbox, space } from 'styled-system';

const StyledButton = styled.button`
  ${space}
  ${flexbox}
  ${({ block, secondary, white, theme }) => css`
    display: ${block ? 'block' : 'inline-block'};
    width: ${block ? '100%' : 'auto'};
    min-width: 11rem;
    padding: 0.875rem;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.428571429;
    color: #fff;
    background: ${theme.colors.purple};
    border: 1px solid transparent;
    border-radius: 0.5rem;
    cursor: pointer;
    text-align: center;
    position: relative;
    outline: none;

    &:hover,
    [disabled] &,
    &[disabled] {
      color: ${theme.colors.purpleLight};
    }

    ${secondary &&
    css`
      background-color: transparent;
      color: ${theme.colors.black};
      border-color: ${theme.colors.purple};

      &:hover {
        color: ${theme.colors.purple};
      }
    `}

    ${white &&
    css`
      border-color: #fff;
      color: #fff;
      &:hover {
        border-color: ${theme.colors.purpleLight};
        color: ${theme.colors.purpleLight};
      }
    `}

      [disabled] &[type="submit"],
      &[disabled][type="submit"] {
      &::after {
        content: '';
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        border: 0.25rem solid currentColor;
        left: 50%;
        border-right-color: transparent;
        animation: spin 1000ms linear infinite;
        margin-left: -0.75rem;
      }
      text-indent: -999px;
      white-space: nowrap;
      overflow: hidden;
    }

    ${theme.mediaQueries.above.small} {
      font-size: 1rem;
      line-height: 1.375;
    }

    @keyframes spin {
      from {
        transform: rotateZ(0deg);
      }
      to {
        transform: rotateZ(360deg);
      }
    }
  `}

  fieldset:disabled & {
    cursor: not-allowed;
  }
`;

const Button = ({ href, type = 'button', children, as, innerRef, ...props }) => {
  if (!href) {
    return (
      <StyledButton ref={innerRef} type={href ? null : type} {...props}>
        {children}
      </StyledButton>
    );
  }

  const isExternal = href.indexOf('http') >= 0 || href.indexOf('tel:') >= 0;

  return isExternal ? (
    <StyledButton ref={innerRef} href={href} as="a" {...props}>
      {children}
    </StyledButton>
  ) : (
    <Link as={as} href={href} passHref legacyBehavior>
      <StyledButton ref={innerRef} as="a" {...props}>
        {children}
      </StyledButton>
    </Link>
  );
};

export default Button;
